<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row align-items-center">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('jobPostingFilter')"
                  >
                    <CIcon
                      v-if="!loading"
                      size="lg"
                      name="cil-filter"
                      class="mr-2"
                    />
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton 
                color="success" 
                square
                size="sm"
                @click="openModalEvent('create','jobPostingForm', null, 'Yeni İş İlanı Ekle')"
              >
                Yeni İş İlanı Ekle
              </CButton>
              <CButton 
                color="info" 
                class="ml-1"
                square
                size="sm"
                @click="openModalEvent('create','careerPositionForm', null, 'Pozisyon / Departman')"
              >
                Pozisyon / Departman
              </CButton>
            </div>
          </div>
        </CCardHeader>
      
        <CCardBody>
          <CDataTable
            :items="jobPostings"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            :loading="loading"
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update','jobPostingForm', lastItem, 'İş İlanı Güncelleme Ekran ('+ lastItem.title+')')"
            v-on:refresh="filterJobPostings(filterParams)"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem
                    @click="openModalEvent('update','jobPostingForm', item, 'İş İlanı Güncelleme Ekranı ('+ item.title+')')">
                    Düzenle
                  </CDropdownItem>
                  <CDropdownItem @click="changeStatus(item)">
                    {{ item.activeFlag ? 'Pasif Et' : 'Aktif Et' }}
                  </CDropdownItem>
                  <CDropdownItem @click="openApplicants(item)">
                    Başvuranları Görüntüle
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('delete','confirm', item, 'İş İlanı Silme Ekranı ('+ item.title+')', item.title+' isimli içeriği silmek üzeresiniz bunu yapmak istediğinizden emin misiniz?')">
                    İlanı Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #createTime="{item}">
              <td> {{ item.createTime && dateFormat(item.createTime) }} </td>
            </template>
            <template #updateTime="{item}">
              <td> {{ item.updateTime && dateFormat(item.updateTime) }} </td>
            </template>
            <template #activeFlag="{item}">
              <td>
                <CIcon v-if="item.activeFlag" class="green" name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="size"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      size="lg"
      ref="form"
      :noFooter="noFooter"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterJobPostings"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>

import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import moment from 'moment'
import { prepareFilterParams } from "@/helpers/custom"


export default {
  name: 'JobPostings',
  components: {
     ConfirmModal, FormModal, FilterSidebar
  },
  data() {
    return {
      noFooter: false,
      modalTitle: '',
      actionType: '',
      module: 'hr',
      form: 'jobPostingForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      lastPage: {},
      openSidebar: false,
      filterForm: 'jobPostingFilter',
      filterParams: {},
      lastItem: {},
      size: 'sm',
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: "Id", _style: 'font-size:12px'},
        {key: 'displayOrder', label: 'Sıra', _style: 'font-size:12px'},
        {key: 'createTime', label: 'İlan Oluşma Tarihi', _style: 'font-size:12px'},
        {key: 'updateTime', label: 'İlan Güncelleme Tarihi', _style: 'font-size:12px'},
        {key: 'careerPositionName', label: 'Pozisyon', _style: 'font-size:12px'},
        {key: 'title', label: 'Başlık', _style: 'font-size:12px'},
        {key: 'langName', label: 'Dil', _style: 'font-size:12px'},
        {key: 'activeFlag', label: 'Aktif', _style: 'font-size:12px'},

      ],
      activePage: 1,
      pages: 0,
      filters: [{
        type: 'string',
        dataIndex: 'careerPosition',
        field: 'careerPositionId'
      }, {
        type: 'string',
        dataIndex: 'title',
        field: 'title'
      },{
        type: 'string',
        dataIndex: 'description',
        field: 'description'
      },{
        type: 'boolean',
        dataIndex: 'activeFlag',
        field: 'activeFlag',
      }, {
        type: 'numeric',
        dataIndex: 'lang',
        field: 'langId',
        comparison: 'eq',
      },{
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt'
      },{
        type: 'date',
        dataIndex: 'endTime',
        field: 'createTime',
        comparison: 'lt',
      },{
        type: 'date',
        dataIndex: 'startUpdateTime',
        field: 'updateTime',
        comparison: 'gt'
      },{
        type: 'date',
        dataIndex: 'endUpdateTime',
        field: 'updateTime',
        comparison: 'lt',
      }
    ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      },
    },
  },
  computed: {
    jobPostings(){
      return this.$store.getters.jobPostings;
    },
    loading(){
      return this.$store.getters.careerLoading;
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.noFooter = false
      if(form == 'careerPositionForm')
        this.noFooter = true

      this.confirmSize = 'sm'
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    async filterJobPostings(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      
      await this.$store.dispatch('jobPosting_list', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
    changeStatus(item){
      let msg
      if(item.activeFlag)
        msg = 'İş ilanını <b> PASİF </b> etmek üzeresiniz bu işlemi yapmak istediğinize emin misiniz?'
      else
        msg = 'İş İlanını <b> AKTİF </b> etmek üzeresiniz bu işlemi yapmak istediğinize emin misiniz?'

      this.openModalEvent('status', 'confirm', item,  item.title+ ' '+ item.careerPositionName, msg )
    },
    openApplicants(item){
      this.$router.push('/hr/careerApplicants?careerId='+item.id)
    },
    dateFormat(data){
      return moment(data).format('DD.MM.YYYY')
    },
    rowClicked(item) {
      this.lastItem = item;
    },
    async crud(item, actionType) {
      var formData = new FormData();
      formData.append('id',item.id ? item.id : '')

      if(actionType == 'status'){
        formData.append('activeFlag', item.activeFlag ?  false : true)
        await this.$store.dispatch('jobPosting_changeStatus', formData)
      }else if(actionType == 'delete'){
        await this.$store.dispatch('jobPosting_delete', formData)
      }else{
        formData.append('title',item.title)
        formData.append('description',item.description ? item.description : '')
        formData.append('langId',item.langId)
        formData.append('activeFlag',item.activeFlag ? item.activeFlag : false)
        formData.append('careerPositionId',item.careerPositionId ? item.careerPositionId : 0)
        formData.append('displayOrder',item.displayOrder ? item.displayOrder : 0)

        if(actionType == 'create'){
          await this.$store.dispatch('jobPosting_add', formData)
        }else{
          await this.$store.dispatch('jobPosting_update', formData)
        }
      }
       
      if(this.$store.getters.careerStatus.success) {
          await this.filterJobPostings(this.filterParams);
          this.openModalEvent()
      }
    }
  },
  created() {
    this.filterJobPostings(this.filterParams);
    this.$store.dispatch('careerPosition_list')
    this.$store.dispatch('careerDepartments_list')
    this.$store.dispatch('lang_list')
  }
}
</script>
